@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://use.typekit.net/azh6app.css");
@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: inherit;
}

p {
  color: $black;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-family: bely-display, sans-serif;
  font-weight: 400;
}

h2 {
  font-family: bely-display, sans-serif;
  font-weight: 400;
}

h3 {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
}

h4 {
  font-family: bely-display, sans-serif;
  font-weight: 400;
}

h5 {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
}

/*Media Queries*/
/* Larger than tablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
}

/* Larger than desktop */
@media (min-width: 1000px) {
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

/* For Screens & Macs */
@media (min-width: 2000px) {
}
