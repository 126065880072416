@import "../../style/variables.scss";

.projectContainer {
  margin: 2rem;
  margin-top: 2rem;
  color: $black;
  text-align: left;
}

.spacer {
  height: 15vh;
}

/*Media Queries*/
/* Larger than tablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .projectContainer {
    margin: 2rem;
    margin-top: 10vh;
    color: $black;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

/* For Screens & Macs */
@media (min-width: 2000px) {
}
