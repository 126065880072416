@import "../../style/variables.scss";

.heroContainer {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  position: relative;
}

.heroText {
  color: $black;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  text-align: center;
  padding: 2rem;
  //line-height: 1rem;
}

.heroCentered {
  //text-align: left;
  //margin-left: auto;
  //margin-right: auto;
  width: 100%;
}

.heroText h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.heroText h2 {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.15rem;
}

#scrollText {
  margin-top: 1rem;
  color: lighten(grey, 40%);
}

.pinkRight {
  top: 0;
  right: 0;
  position: absolute;
  width: 20vh;
  transform: translateY(-10%) scale(1.3);
  transform-origin: right;
  z-index: 1;
}

.fries {
  top: 0;
  left: 0;
  position: absolute;
  width: 150px;
  transform: scale(1.5);
  z-index: 1;
}

.pinkLeft {
  bottom: 10vh;
  left: 0;
  position: absolute;
  width: 20vh;
  transform: translateX(-40%) scale(1.9);
  z-index: 1;
}

.containment {
  width: 100vw;
  height: 90vh;
  position: relative;
}

.circle {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%) scale(1.5);
  z-index: -3;
}

.heroButton {
  width: 8rem;
  height: 2rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.5rem;
  text-transform: uppercase;
  background-color: transparent;
  outline: none;
  border-radius: 4rem;
  border-style: solid;
  border-color: $black;
  color: $black;
  padding: 0.25rem;
  letter-spacing: 0.15rem;
  border-width: 1px;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
}

.heroButton:hover {
  background-color: $pink;
  border-color: $pink;
  color: white;
  transition: 0.3s ease-in-out;
}

/*Media Queries*/
/* Larger than tablet (also point when grid becomes active) */
@media (min-width: 400px) {
  .mainNav li {
    padding: 0.5rem;
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .circle {
    transform: translateY(-50%) scale(1);
  }
  .heroText h1 {
    font-size: 3.5rem;
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .heroContainer {
    height: 100vh;
  }
  .circle {
    transform: translateY(-50%) scale(0.7);
  }
  .pinkRight {
    width: 50vh;
  }
  .pinkLeft {
    bottom: 0;
    width: 50vh;
  }
  .fries {
    width: 400px;
    transform: scale(1.5);
    z-index: 1;
    top: auto;
    left: auto;
    bottom: 10vh;
    right: 0;
  }
  .heroText h1 {
    font-size: 4.5rem;
  }
  .heroText h2 {
    font-size: 1.75rem;
    letter-spacing: 0.5rem;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

/* For Screens & Macs */
@media (min-width: 2000px) {
}
