$yellow: #fff683;

$pink: #ff77c6;

$blue: #66e2ea;

$purple: #4841fc;

$grey: #cecece;
$black: #262525;
$dk_black: #94959a;
