@import "../../style/variables.scss";

.mainNav {
  height: 80vh;
  background-color: $black;
  color: white;
  width: 100%;
  position: fixed;
  font-family: museo-sans, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  bottom: 0;
  font-size: 2rem;
  letter-spacing: 0.2rem;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  z-index: 3;
  //padding-top: 10vh;
  padding: 2rem;
}

.centerLi {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mainNav li {
  list-style: none;
  display: block;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 2rem;
  width: fit-content;
  text-align: center;
  font-size: 1.5rem;
}

.mainNav li:hover {
  color: $pink;
  transition: 0.5s ease-in-out;
}

/*Square Grids*/
.gridContainer {
  height: auto;
  display: flex;
  //flex: 1 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gridContainer > .projectThumbs {
  background-color: plum;
  object-fit: cover;
  display: flex;
  flex-basis: calc(50% - 50px);
  flex-direction: column;
  margin: 10px;
  border-radius: 1rem;
  cursor: pointer;
}

.projectThumbs:before {
  content: "";
  display: block;
  padding-top: 100%;
}

//Larger than tablet (also point when grid becomes active) */
//@media (min-width: 550px)

//Larger than tablet
//@media (min-width: 750px)

//Larger than desktop
//@media (min-width: 1000px)

//Larger than Desktop HD
//@media (min-width: 1200px)

//For Screens & Mac
//@media (min-width: 2000px)
