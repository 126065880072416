@import "../../style/variables.scss";

.tagContainer {
  display: flex;
  margin-top: 1rem;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;

  .individualTag {
    margin-right: 1rem;
    flex-shrink: 0;

    p {
      font-size: 10px;
      padding: 0.5rem;
      color: #58a6ff;
      background-color: rgba(56, 139, 253, 0.1);
      border-radius: 5rem;
    }
  }
}

.tagContainer:-webkit-scrollbar {
  display: none;
}
