@import "../../style/variables.scss";

.textareaContainer {
  margin-top: 2rem;
}

.textContainer {
  h2 {
    margin-top: 3rem;
  }
  p {
    margin-top: 1rem;
  }
}

.subhead {
  font-family: museo-sans, sans-serif;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.headlines {
  position: relative;
}

.backing {
  color: transparent;
  font-size: 5rem;
  -webkit-text-stroke: 1px $pink;
  position: absolute;
  transform: translateY(-60%);
  z-index: -3;
}
