@import "../../style/variables.scss";

.quicklinksContainer {
  margin-top: 2rem;
}

.quicklinksContainer button {
  width: 8rem;
  height: 2rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.5rem;
  text-transform: uppercase;
  background-color: transparent;
  outline: none;
  border-radius: 4rem;
  border-style: solid;
  border-color: $black;
  color: $black;
  letter-spacing: 0.15rem;
  border-width: 1px;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &.selected {
    background-color: $black;
    color: white;
  }
}

.quicklinksContainer button:hover {
  background-color: $pink;
  border-color: $pink;
  color: white;
  transition: 0.2s ease-in-out;
}
