@import "../../style/variables.scss";

.workContainer {
  margin: 2rem;
  margin-top: 10vh;
  color: $black;
  text-align: center;
}

.workContainer h1,
.workContainer h2 {
  font-size: 3rem;
}

.workContainer h4 {
  font-size: 2rem;
  color: $black;
}

.workContainer h5 {
  color: $black;
  font-size: 1rem;
}

.filterContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.filterContainer button {
  width: 8rem;
  height: 2rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.5rem;
  text-transform: uppercase;
  background-color: transparent;
  outline: none;
  border-radius: 4rem;
  border-style: solid;
  border-color: $black;
  color: $black;
  padding: 0.25rem;
  letter-spacing: 0.15rem;
  border-width: 1px;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &.selected {
    background-color: $black;
    color: white;
  }
}

.filterContainer button:hover {
  background-color: $pink;
  border-color: $pink;
  color: white;
  transition: 0.2s ease-in-out;
}

.moreContainer button {
  width: 8rem;
  height: 2rem;
  font-family: "Open Sans", sans-serif;
  font-size: 0.5rem;
  text-transform: uppercase;
  background-color: transparent;
  outline: none;
  border-radius: 4rem;
  border-style: solid;
  border-color: $black;
  color: $black;
  padding: 0.25rem;
  letter-spacing: 0.15rem;
  border-width: 1px;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.moreContainer button:hover {
  background-color: $black;
  border-color: $black;
  color: white;
  transition: 0.2s ease-in-out;
}

.projectCard {
  max-width: 350px;
  height: fit-content;
  border-radius: 1rem;
  background-color: white;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  margin-bottom: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 4px rgba(0, 0, 0, 0.15);
}

.imageCrop {
  width: fit-content;
  height: 350px;
  overflow: hidden;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.imageCrop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projectDetails {
  padding: 1rem;
}

.spacer {
  width: 100%;
  height: 20vh;
}

/*Blue Overlay on Hover*/
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.2s ease;
  background-color: $blue;
  border-radius: 1rem;
  z-index: 1;
}

.projectCard:hover .overlay {
  opacity: 0.75;
}

/*Media Queries*/
/* Larger than tablet (also point when grid becomes active) */
@media (min-width: 550px) {
}

/* Larger than tablet */
@media (min-width: 750px) {
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .projectCard {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
}

/* For Screens & Macs */
@media (min-width: 2000px) {
}
